<template>
    <div class="list-view">
        <page-title></page-title>
        <div class="filters-container">
            <el-input placeholder="输入关键字" v-model="search" clearable class="filter-item"></el-input>
            <el-button @click="initData" type="primary" icon="el-icon-search" class="filter-item">搜索 </el-button>
            <el-button
                @click="download"
                type="primary"
                icon="el-icon-download"
                :loading="downloading"
                class="filter-item"
                >导出EXCEL
            </el-button>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
        >
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="操作名称" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="type" label="操作类型" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="userId" label="用户ID" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="username" label="用户名" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="desc" label="描述" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="reqMethod" label="调用方法" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="reqUrl" label="请求地址" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="reqParams" label="请求参数" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="reqIp" label="请求IP" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="resp" label="返回结果" align="center" width="80">
                <template v-slot="{ row }">
                    <el-button @click="viewResp(row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="time" label="操作时间" width="150"> </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
        <el-dialog :visible.sync="showResp" title="返回结果">
            <pre
                >{{ resp }}
           </pre
            >
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'OperationLogList',
    mixins: [pageableTable],
    data() {
        return {
            search: '',
            url: '/operationLog/all',
            downloading: false,
            sortStr: 'time,desc',
            resp: '',
            showResp: false
        };
    },
    computed: {},
    methods: {
        beforeGetData() {
            if (this.search) {
                return { search: this.search };
            }
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/operationLog/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        viewResp(row) {
            try {
                this.resp = JSON.stringify(JSON.parse(row.resp), null, 4);
            } catch (e) {
                console.log(e);
                this.resp = row.resp;
            }
            this.showResp = true;
        }
    }
};
</script>
<style lang="less" scoped></style>
